import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bulma-components"

const NotFoundPage = () => {
  
  const isBrowser = typeof window !== "undefined"

  if (!isBrowser) return <>{' '}</>
  
  return (
  <Layout>
    <Seo title="404: Not found" />
    <Container>
      <h1>NOT FOUND</h1>
      <p>This page doesn't exist anymore...</p>
    </Container>
  </Layout>
)}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;